import { useCallback, useMemo, useState } from 'react';
import { BaseMutation } from '@remento/types/base-entity';
import { ProjectStatus } from '@remento/types/project';
import { areSetsEqual } from '@remento/utils/set';

import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { toast } from '@/components/RMToast/RMToast';
import { submitForm } from '@/modules/form/form';
import { useServices } from '@/Services';
import { EntityMutation } from '@/services/api/cache';
import { usePersonQuery } from '@/services/api/person';
import { useIsCurrentUserTheStoryteller, useProjectQuery } from '@/services/api/project';
import { createProjectCronString, parseProjectCronString } from '@/services/api/project/project.utils';
import { captureException } from '@/utils/captureException';
import { formatTimeWithTimezone, getCurrentTimezoneInfo, Weekday } from '@/utils/tz';

import { PromptSettingsEdit } from '../components/PromptSettingsEdit/PromptSettingsEdit';
import { createPromptSettingsForm } from '../forms/prompt-settings-form';
import { usePromptFrequencyTextByForm as usePromptFrequencyTextFromForm } from '../hooks/usePromptFrequencyText';

export interface PromptSettingsEditContainerProps {
  projectId: string;
  onClose: () => void;
}

interface SaveConfirmationState {
  source: 'submit' | 'close';
  changes: string[];
}

export function PromptSettingsEditContainer({ projectId, onClose }: PromptSettingsEditContainerProps) {
  const { entityCacheManagerService, projectService, projectAnalyticsService } = useServices();

  const projectQuery = useProjectQuery(projectId);
  const storytellerPersonQuery = usePersonQuery(projectQuery.data?.subjectPersonIds[0]);
  const storytellerFirstName = storytellerPersonQuery.data?.name?.first ?? null;
  const isCurrentUserTheStoryteller = useIsCurrentUserTheStoryteller(projectId);

  const form = useMemo(() => {
    const cronString = projectQuery.data?.notifications.interval;
    const parsedCronString = cronString != null ? parseProjectCronString(cronString) : null;
    return createPromptSettingsForm({
      isReceivingPrompts: projectQuery.data?.status !== ProjectStatus.PAUSED,
      time: parsedCronString?.localTime,
      weekdays: parsedCronString?.weekdays,
    });
  }, [projectQuery.data?.notifications.interval, projectQuery.data?.status]);
  const promptFrequencyText = usePromptFrequencyTextFromForm(projectId, form);

  const [saveConfirmationState, setSaveConfirmationState] = useState<SaveConfirmationState | null>(null);

  const handleSaveConfirmation = useCallback(
    async (source: 'submit' | 'close') => {
      if (projectQuery.data == null) {
        return;
      }

      await submitForm(form, (data) => {
        const parsedCronString = parseProjectCronString(projectQuery.data.notifications.interval);
        const frequencyChanged =
          data.isReceivingPrompts &&
          (data.time !== parsedCronString.localTime || !areSetsEqual(data.weekdays, parsedCronString.weekdays));
        const pauseChanged = data.isReceivingPrompts
          ? projectQuery.data.status === ProjectStatus.PAUSED
          : projectQuery.data.status !== ProjectStatus.PAUSED;

        // The new values are the same as the current values, don't show the confirmation
        if (!frequencyChanged && !pauseChanged) {
          onClose();
          return;
        }

        // The user is pausing the project
        if (pauseChanged && projectQuery.data.status !== ProjectStatus.PAUSED) {
          setSaveConfirmationState({
            source,
            changes: ['Prompts to be paused. You can resume sending prompts anytime.'],
          });
          return;
        }

        // Show the new frequency. We should show this when editing the frequency or resuming the project.
        const listFormat = new Intl.ListFormat('en', { style: 'long' });
        const allWeekdays = Object.values(Weekday);
        const formattedWeekdays = listFormat.format(
          Array.from(data.weekdays).sort((a, b) => {
            const aIndex = allWeekdays.indexOf(a);
            const bIndex = allWeekdays.indexOf(b);
            return aIndex - bIndex;
          }),
        );
        const tz = getCurrentTimezoneInfo();
        const formattedTime = formatTimeWithTimezone(data.time, tz);

        setSaveConfirmationState({
          source,
          changes: [`Prompts to be sent every ${formattedWeekdays} at ${formattedTime}.`],
        });
      });
    },
    [form, onClose, projectQuery.data],
  );

  const handleSave = useCallback(async () => {
    if (projectQuery.data == null) {
      return;
    }

    await submitForm(form, async (data) => {
      try {
        const mutations: EntityMutation<BaseMutation>[] = [];

        if (!data.isReceivingPrompts) {
          mutations.push(...projectService.createSetProjectPausedMutation(projectQuery.data));
        } else {
          const cronString = createProjectCronString(data.time, data.weekdays);
          mutations.push(...projectService.createSetProjectNotificationIntervalMutation(projectQuery.data, cronString));

          if (projectQuery.data.status === ProjectStatus.PAUSED) {
            mutations.push(...projectService.createSetProjectActiveMutation(projectQuery.data));
          }

          projectAnalyticsService.onProjectDefaultNotificationsIntervalChanged(
            cronString,
            data.time,
            data.weekdays,
            'onboarding',
          );
        }

        await entityCacheManagerService.mutate(mutations);
        onClose();
      } catch (error) {
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      }
    });
  }, [entityCacheManagerService, form, onClose, projectAnalyticsService, projectQuery.data, projectService]);

  const handleCloseSaveConfirmation = useCallback(() => {
    if (saveConfirmationState?.source === 'close') {
      onClose();
      return;
    }
    setSaveConfirmationState(null);
  }, [onClose, saveConfirmationState?.source]);

  return (
    <>
      <PromptSettingsEdit
        form={form}
        storytellerFirstName={storytellerFirstName}
        isCurrentUserTheStoryteller={isCurrentUserTheStoryteller ?? false}
        promptFrequencyText={promptFrequencyText}
        onSubmit={() => handleSaveConfirmation('submit')}
        onClose={() => handleSaveConfirmation('close')}
      />

      {saveConfirmationState != null && (
        <RMConfirmationModal
          open
          confirmLabel="Save changes"
          title={
            saveConfirmationState.source === 'submit'
              ? 'Confirm changes to prompt settings'
              : 'Save changes to prompt settings'
          }
          message={
            <>
              {saveConfirmationState.source === 'submit'
                ? 'You have selected:'
                : 'You have made updates to your prompt settings but did not save your changes. Would you like to save these changes?'}
              <br />
              <br />
              {saveConfirmationState.changes.join('\n\n')}
            </>
          }
          onConfirm={handleSave}
          onCancel={handleCloseSaveConfirmation}
          onClose={() => setSaveConfirmationState(null)}
        />
      )}
    </>
  );
}
